import { VFC } from 'react'

import { Color } from '../ThemeProvider'
import { BlobProps } from './type'

export const Blob: VFC<Partial<BlobProps>> = ({ size, fill }) => {
  return (
    <svg viewBox='0 0 440 440' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M220,426.48999088909477C258.407309748719,426.82879146413245,290.2727859266756,400.37549131773613,322.99311639256365,380.26041731422396C356.38199042304757,359.7343510290037,395.8096099069458,343.3700563749318,412.7215105114774,308.01296480745094C429.714284897688,272.4867933848373,420.5211168820112,230.9025285756091,412.9155219894667,192.26293536320475C405.6341907925587,155.2707369266156,396.5909513977121,116.73044716561174,369.84099025604615,90.162042845999C343.84894365078276,64.34640895981647,306.3885591173811,52.997843921233915,269.86320016507085,50.18153550763776C237.39831696025422,47.678312367913925,209.26505886692428,68.0797481691747,177.52198831591312,75.33317767411661C143.02530580346746,83.21581947325461,101.3019397816187,70.61096306643782,75.32131088675874,94.63520224951205C49.26828860163897,118.72638346581854,48.429897363392854,159.76792594319204,47.84256626682752,195.24749787929176C47.305195538538804,227.70905750412362,60.49343593042843,257.22116173361803,72.12810432976929,287.53083200258527C84.55399226967174,319.9017215999989,92.90026513139708,354.7087912071768,118.16805220012132,378.453603715861C146.0833518269132,404.686354291093,181.69455680657362,426.1520889046571,220,426.48999088909477'
        fill={fill || Color.purple1}
      />
    </svg>
  )
}
