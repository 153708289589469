import React from 'react'
import { VFC } from 'react'

import { Color } from '../../../components/ThemeProvider'
import { IconProps } from '../type'

const Icon: VFC<Partial<IconProps>> = ({ size, line }) => {
  return (
    <svg width={size || 24} height={size || 24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 2C16.5523 2 17 2.44772 17 3H20C20.5523 3 21 3.44772 21 4V8V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V8V4C3 3.44772 3.44772 3 4 3H7C7 2.44772 7.44772 2 8 2C8.55228 2 9 2.44772 9 3H15C15 2.44772 15.4477 2 16 2ZM7 5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5H15C15 5.55228 15.4477 6 16 6C16.5523 6 17 5.55228 17 5H19V7H5V5H7Z'
        fill={Color[line || 'gray3']}
      />
    </svg>
  )
}

export const Calendar = React.memo(Icon)
