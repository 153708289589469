import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React, { VFC } from 'react'
import { IndexPageQuery } from 'src/type'

import { Blob } from '../components/Blob'
import { Layout } from '../components/Layout'
import { Post } from '../components/Post'
import { Spacer } from '../components/Spacer'

const IndexPage: VFC<PageProps<IndexPageQuery>> = (props) => {
  const { data } = props
  const postList = data.allMarkdownRemark.nodes
  return (
    <>
      <BlobWrapper>
        <Blob />
      </BlobWrapper>
      <Layout title='김춘식의 짱쎈 블로그'>
        <PostList>
          {postList.map((post, index) => {
            return (
              <React.Fragment key={post.fields.slug}>
                <Post {...post} />
                {index < postList.length - 1 && <Spacer y={1.5} />}
              </React.Fragment>
            )
          })}
        </PostList>
      </Layout>
    </>
  )
}

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(359deg);
}
`

const BlobWrapper = styled.div`
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  width: 45rem;
  height: 45rem;
  pointer-events: none;
  overflow: hidden;

  svg {
    position: relative;
    animation: ${rotate} 60s linear infinite;
  }

  @media screen and (max-width: 1024px) {
    width: 30rem;
    height: 30rem;
  }

  @media print {
    display: none;
  }
`

const PostList = styled.div`
  width: 100%;
  margin-top: 5rem;
`

export const pageQuery = graphql`
  {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date
          tags
          cover {
            publicURL
          }
        }
        timeToRead
        excerpt(truncate: true)
      }
    }
  }
`

export default IndexPage
