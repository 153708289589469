import { css } from '@emotion/react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import { VFC } from 'react'
import { PostSummary } from 'src/type'

import { Icon } from '../Icon'
import { Spacer } from '../Spacer'
import { Text } from '../Text'

export const Post: VFC<PostSummary> = (props) => {
  const {
    fields: { slug },
    frontmatter: { title, date, tags, cover },
    timeToRead,
    excerpt
  } = props
  return (
    <StyledPost
      onClick={() => navigate(slug)}
      onMouseDown={(event) => {
        if (event.button === 1) {
          window.open(`${window.location.origin}${slug}`, '_blank')
        }
      }}
    >
      <ContentBox>
        <TextBox>
          <Text as='span' size={24} weight={400} isWrap>
            {title}
          </Text>
          <Spacer y={0.2} />
          <PostSubInfo>
            <SubInfo>
              <Icon.Calendar line='gray2' />
              <Text as='span' color='gray1' weight={200} isWrap>
                {dayjs(date).format('YYYY년 MM월 DD일')}
              </Text>
            </SubInfo>
            {/* <SubInfo>
              <Icon.Clock line='gray2' />
              <Text as='span' color='gray1' weight={200} isWrap>
                {timeToRead}분
              </Text>
            </SubInfo> */}
          </PostSubInfo>
          <Spacer y={0.4} />
          <Text as='p' color='gray2' weight={200} isWrap>
            {excerpt !== '…' && excerpt}
          </Text>
        </TextBox>
        {cover && <Image alt={`${title} 글의 커버 이미지`} src={cover.publicURL} />}
      </ContentBox>
      <Spacer y={0.5} />
      <TagBox>
        {tags.map((tag) => {
          return (
            <Tag key={`${title}-${date}-${tag}`}>
              <Text as='span' weight={200} color='primary2'>
                #{tag}
              </Text>
            </Tag>
          )
        })}
      </TagBox>
    </StyledPost>
  )
}

const StyledPost = styled.a`
  transition: transform 0.25s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;

  &:hover {
    transform: translateX(-20px);
  }

  @media screen and (max-width: 1024px) {
    &:hover {
      transform: translateY(-20px);
    }
  }
`

const Image = styled.img`
  flex: 0 0 auto;
  width: 120px;
  height: 120px;
  /* padding-left: 2rem; */
  margin-left: auto;
  border-radius: 0.25rem;
  object-fit: cover;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
`

const PostSubInfo = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`

const SubInfo = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 0.25rem;
  }
`

const ContentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TagBox = styled.div`
  display: flex;
  & > span:not(:last-child) {
    margin-right: 0.5rem;
  }
`

const Tag = styled.span`
  padding: 0.2rem 0.4rem;
  ${({ theme }) => css`
    background-color: ${theme.color.gray3};
    border-radius: 0.25rem;
  `}
`
